
import AppHeader from '@/components/header/AppHeader.vue'
import ThreeTabsNav from '@/components/homepage/ThreeTabsNav.vue'
import TabsBox from '@/components/homepage/TabsBox.vue'
import TodosList from '@/components/homepage/TodosList.vue'

import { IonPage, IonContent } from '@ionic/vue'

export default {
  components: {
    IonPage,
    IonContent,
    AppHeader,
    TabsBox,
    ThreeTabsNav,
    TodosList,
  },
}
