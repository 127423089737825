
import CathegoryHeading from '@/components/homepage/CathegoryHeading.vue'

import { IonGrid, IonRow, IonIcon } from '@ionic/vue'

import { schoolOutline } from 'ionicons/icons'

export default {
  components: {
    IonGrid,
    IonRow,
    IonIcon,
    CathegoryHeading,
  },
  setup() {
    return {
      schoolOutline,
    }
  },
  data() {
    return {
      tasks: [
        'Prepare for tomorrow math exam',
        'Write an essay on "How I play hockey"',
        'Buy a Biology exercise book',
      ],
    }
  },
}
