<template>
  <ion-grid>
    <ion-row>
      <cathegory-heading
        :icon-name="schoolOutline"
        heading-text="school"
        link-text="show more"
      />
    </ion-row>
    <ion-row
      v-for="(task, index) in tasks"
      :key="index"
      class="tasks"
    >
      <ion-item class="white-container list-item" lines="none">
        {{ task }}
        <ion-icon :icon="schoolOutline" class="light-icon" />
      </ion-item>
    </ion-row>
  </ion-grid>
</template>

<script lang="ts">
import CathegoryHeading from '@/components/homepage/CathegoryHeading.vue'

import { IonGrid, IonRow, IonIcon } from '@ionic/vue'

import { schoolOutline } from 'ionicons/icons'

export default {
  components: {
    IonGrid,
    IonRow,
    IonIcon,
    CathegoryHeading,
  },
  setup() {
    return {
      schoolOutline,
    }
  },
  data() {
    return {
      tasks: [
        'Prepare for tomorrow math exam',
        'Write an essay on "How I play hockey"',
        'Buy a Biology exercise book',
      ],
    }
  },
}
</script>

<style scoped>
.white-container {
  margin: 7px 0;
  padding: 5px;
}

.light-icon {
  margin-left: auto;
  color: var(--ion-color-medium);
}
</style>
